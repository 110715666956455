import React from 'react';
import { motion } from 'motion/react';
import { FaXTwitter, FaTiktok, FaDiscord, FaYoutube } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer className="font-Inter bg-[#202020] py-4 text-[#F9F6FF] mt-auto select-none">
      <div className="container mx-auto flex flex-col items-center justify-between gap-4 px-4 md:flex-row">
        <p className="text-center text-sm font-medium md:text-left">
          ©COSYAIM 2024. All rights reserved
        </p>

        <div className="flex justify-center gap-4 md:justify-start">
          {/* Social Links */}
          <motion.a
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#FF9BCF] transition-colors duration-300"
            aria-label="Twitter"
          >
            <FaXTwitter size={24} />
          </motion.a>
          <motion.a
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
            href="https://www.tiktok.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#FF9BCF] transition-colors duration-300"
            aria-label="TikTok"
          >
            <FaTiktok size={24} />
          </motion.a>
          <motion.a
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
            href="https://discord.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#FF9BCF] transition-colors duration-300"
            aria-label="Discord"
          >
            <FaDiscord size={24} />
          </motion.a>
          <motion.a
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
            href="https://youtube.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#FF9BCF] transition-colors duration-300"
            aria-label="YouTube"
          >
            <FaYoutube size={24} />
          </motion.a>
        </div>

        <motion.a
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 300, damping: 10 }}
          href="#privacy-policy"
          className="text-center text-sm font-medium hover:text-[#FF9BCF] transition-colors duration-300 md:text-right"
        >
          Privacy Policy
        </motion.a>
      </div>
    </footer>
  );
};

export default Footer;
