import React from 'react';
import { motion } from 'motion/react';
import { ReactTyped } from 'react-typed';

const Hero = () => {
  return (
    <div className="font-Inter text-[#202020] select-none bg-gradient-to-br from-[#F9F6FF] to-[#EAE2FF]">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center px-4">
        {/* Section Highlight */}
        <p className="text-[#FF9BCF] font-bold p-2 tracking-widest text-sm md:text-base uppercase">
          Aim with Heart
        </p>
        {/* Title */}
        <h1 className="md:text-8xl sm:text-6xl text-5xl font-extrabold md:py-6 tracking-tight">
          COSYAIM
        </h1>
        {/* Animated Text */}
        <div className="flex justify-center items-center">
          <p className="md:text-4xl sm:text-3xl text-xl font-medium">
            Competing in&nbsp;
          </p>
          <ReactTyped
            className="md:text-4xl sm:text-3xl text-xl font-bold animated-gradient-text"
            strings={['VALORANT', 'CS2', 'APEX LEGENDS']}
            typeSpeed={80}
            backSpeed={40}
            loop
          />
        </div>
        {/* Subtitle */}
        <p className="md:text-xl text-lg font-light text-gray-400 mt-4 leading-relaxed">
          cosyaim is an aim group dedicated to building its reach and promoting
          hidden talents.
        </p>
        {/* Button (wrapped with <a> for Discord link) */}
        <a
          href="https://discord.gg/ScQE38x4rj"
          target="_blank"
          rel="noopener noreferrer"
        >
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
            className="bg-[#FF9BCF] w-[200px] rounded-md font-medium mt-8 mx-auto py-3 text-[#202020] shadow-lg hover:bg-[#FFB8D6] transition-colors duration-300"
          >
            Join Now
          </motion.button>
        </a>
      </div>
    </div>
  );
};

export default Hero;
