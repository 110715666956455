import React from 'react';

const Navbar = () => {
  return (
    <div className="font-Inter flex justify-between items-center h-24 max-w-[1700px] mx-auto px-4 bg-[#202020] text-[#F9F6FF] select-none">
      {/* Logo */}
      <h1 className="text-4xl font-extrabold text-[#FF9BCF] tracking-wide cursor-pointer">
        COSYAIM.
      </h1>
    </div>
  );
};

export default Navbar;
